import React from 'react';
import cls from 'classnames';
import {
  TextButton as TextButtonTPA,
  TextButtonPriority,
  TextButtonProps,
} from 'wix-ui-tpa/cssVars';

import {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Spinner } from '../Spinner';

import classes from './TextButton.scss';

interface IProps extends Omit<TextButtonProps, 'as' | 'priority'> {
  // wired to user settings
  wired?: boolean;
  loading?: boolean;
  variant?: 'primary' | 'secondary' | 'link';
}

// eslint-disable-next-line react/display-name
export const TextButton = React.forwardRef(
  (props: OverridableComponentProps<IProps, typeof TextButtonTPA>, ref) => {
    const { variant, loading, wired, className, children, ...rest } = props;

    return (
      <TextButtonTPA
        ref={ref}
        className={cls(classes.root, className, { [classes.wired]: wired })}
        priority={variant as TextButtonPriority}
        {...rest}
      >
        {loading ? <Spinner className={classes.spinner} size={20} /> : children}
      </TextButtonTPA>
    );
  },
) as OverridableComponent<IProps, typeof TextButtonTPA>;

TextButton.displayName = 'wui/TextButton';
TextButton.defaultProps = {
  wired: false,
  variant: 'primary',
};
