import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from '../Box';
import { OverridableComponent } from '../OverridableComponent';

import classes from './Container.scss';

interface IContainerProps extends React.ComponentProps<typeof Box> {
  fluid?: boolean;
}

export const Container: OverridableComponent<IContainerProps, 'div'> =
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, IContainerProps>((props, ref) => {
    const { children, className, fluid, ...rest } = props;

    const { isMobile } = useEnvironment();

    return (
      <Box
        ref={ref}
        direction="vertical"
        className={cls(classes.root, className, {
          [classes.fluid]: fluid,
          [classes.mobile]: isMobile,
        })}
        {...rest}
      >
        {children}
      </Box>
    );
  });

Container.displayName = 'wui/Container';
