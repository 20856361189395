import React from 'react';

import {
  IconButton as IconButtonTPA,
  IconButtonProps,
} from 'wix-ui-tpa/cssVars';
import {
  OverridableComponent,
  OverridableComponentProps,
} from 'wui/OverridableComponent';

interface IProps extends Omit<IconButtonProps, 'innerRef'> {}

export const IconButton = React.forwardRef(
  (props: OverridableComponentProps<IProps, 'button'>, ref) => {
    const { ...rest } = props;

    // @ts-expect-error
    return <IconButtonTPA innerRef={handleRef} {...rest} />;

    // ui-tpa 🤷‍♂️
    function handleRef(el: HTMLButtonElement) {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        // @ts-expect-error
        ref.current = el?.wrappedComponentRef?.innerComponentRef || null;
      }
    }
  },
) as OverridableComponent<IProps, 'button'>;

IconButton.displayName = 'wui/IconButton';
