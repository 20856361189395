import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { Typography } from '../Typography';

import classes from './DialogContentText.scss';

interface IDialogContentTextProps extends TPAComponentProps {
  alert?: boolean;
  children?: React.ReactNode;
}

export function DialogContentText(props: IDialogContentTextProps) {
  const { isMobile } = useEnvironment();

  let text: React.ReactElement;

  if (props.children && typeof props.children === 'string') {
    text = (
      <Typography variant={isMobile ? 'p2-14' : 'p2-16'}>
        {props.children}
      </Typography>
    );
  } else {
    text = props.children as React.ReactElement;
  }

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.root, {
        [classes.alert]: props.alert,
      })}
    >
      {text}
    </div>
  );
}

DialogContentText.displayName = 'wui/DialogContentText';
