import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Dialog } from '../Dialog';

import classes from './AlertDialog.scss';

interface IAlertDialogProps extends React.ComponentProps<typeof Dialog> {}

export function AlertDialog(props: IAlertDialogProps) {
  const { className, paperProps, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <Dialog
      size="sm"
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.fullscreen]: props.fullscreen,
      })}
      paperProps={{
        ...paperProps,
        className: cls(classes.paper, paperProps?.className),
      }}
      {...rest}
    />
  );
}

AlertDialog.displayName = 'wui/AlertDialog';
