import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';

export const getGroupsCssVars: CustomCssVarsFn = ({ styleParams }) => ({
  showHeaderTitle: getDisplayForCssVar(styleParams, 'showHeaderTitle', 'block'),
  showSearchSorting: getDisplayForCssVar(
    styleParams,
    'showSearchSorting',
    'block',
  ),
  showMemberCount: getDisplayForCssVar(styleParams, 'showMemberCount', 'block'),
  'showMemberCount--inline': getDisplayForCssVar(
    styleParams,
    'showMemberCount',
    'inline',
  ),
  showAdminBadge: getDisplayForCssVar(styleParams, 'showAdminBadge', 'block'),
  'showAdminBadge--inline': getDisplayForCssVar(
    styleParams,
    'showAdminBadge',
    'inline',
  ),
  showImage: getDisplayForCssVar(styleParams, 'showImage', 'block'),
  'showImage--inline': getDisplayForCssVar(styleParams, 'showImage', 'inline'),
  'showImage--flex': getDisplayForCssVar(styleParams, 'showImage', 'flex'),
  showCreateGroupButton: getDisplayForCssVar(
    styleParams,
    'showCreateGroupButton',
    'block',
  ),
  'showCreateGroupButton--inline-block': getDisplayForCssVar(
    styleParams,
    'showCreateGroupButton',
    'inline-block',
  ), // use in sidebar layout
  showGroupsToJoin: getDisplayForCssVar(
    styleParams,
    'showGroupsToJoin',
    'block',
  ),
});
