import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { createStylesParams, StyleParamType } from '@wix/tpa-settings';

import commonStyles, {
  customCssVars as commonCssVars,
} from 'Groups/stylesParams';
import { DEFAULT_GRID_CARD_SPACING } from 'settings/consts';

export const WIDGET_MAX_SPACING = 100;

export type IStylesParams = {
  sideBySideCardSpacing: StyleParamType.Number;
};

const stylesParams = createStylesParams<IStylesParams>({
  sideBySideCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    type: StyleParamType.Number,
  },
});
export default { ...commonStyles, ...stylesParams };

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = (params) => {
  return {
    ...commonCssVars(params),
  };
};
