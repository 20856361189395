import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { selectGroupSlug, selectRequiredPricingPlanIds } from 'store/selectors';

import { COMPONENT_ID } from 'common/utils/utils';

import {
  GROUPS_APP_DEFINITION_ID,
  INVITE_PAID_PLANS,
} from '../../../../../constants';

export function usePricingPlansQueryParams(groupId: string) {
  const { t } = useTranslation();

  const slug = useSelector(selectGroupSlug(groupId));
  const planIds = useSelector(selectRequiredPricingPlanIds(groupId));

  if (!planIds.length) {
    return null;
  }

  const navigateToSectionProps = {
    appDefinitionId: GROUPS_APP_DEFINITION_ID,
    sectionId: COMPONENT_ID.GROUP,
    queryParams: {
      invite: INVITE_PAID_PLANS,
    },
    state: slug,
    shouldRefreshIframe: true,
  };

  let verticalStatusContent: string | undefined;
  try {
    verticalStatusContent = btoa(
      JSON.stringify({
        buttonText: t('groups-web.restriction.plans.status.cta'),
        contentText: t('groups-web.restriction.plans.status.content'),
      }),
    );
  } catch (e) {}

  const queryParams = {
    planIds: planIds.join(','),
    navigateToSectionProps: btoa(JSON.stringify(navigateToSectionProps)),
    verticalStatusContent,
    biOptions: btoa(
      JSON.stringify({
        referralInfo: 'pp restricted group',
        referralId: slug,
      }),
    ),
  };

  return JSON.stringify(queryParams);
}
