import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

type SupportedDisplayValues =
  | 'none'
  | 'block'
  | 'inline'
  | 'inline-block'
  | 'flex';

export function getDisplayForCssVar(
  styleParams: Parameters<CustomCssVarsFn>[number]['styleParams'],
  cssVarName: string,
  ifTrue: SupportedDisplayValues,
  ifFalse: SupportedDisplayValues = 'none',
) {
  return styleParams.booleans[cssVarName] ? ifTrue : ifFalse;
}
