import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './ListItemIcon.scss';

interface IListItemIconProps extends TPAComponentProps {
  children?: React.ReactNode;
}

export function ListItemIcon(props: IListItemIconProps) {
  const { children, className } = props;

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.root, className)}
    >
      {children}
    </div>
  );
}

ListItemIcon.displayName = 'wui/ListItemIcon';
