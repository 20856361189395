import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { GroupImage } from 'common/components/GroupImage';
import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import widgetSettingsParams from 'GroupsListWidget/settingsParams';

import { getDirection } from './helpers';

import classes from './GroupStripeItem.scss';

interface IGroupStripeItemSkeletonProps {
  even?: boolean;
}

export function GroupStripeItemSkeleton(props: IGroupStripeItemSkeletonProps) {
  const settings = useSettings();
  return (
    <Card
      gap="SP1"
      padding="SP0"
      height="240px"
      direction={getDirection(
        settings.get(widgetSettingsParams.imageLayout),
        props.even,
      )}
    >
      <div className={classes.image}>
        <GroupImage />
      </div>
      <CardContent verticalAlign="middle" width="100%">
        <Skeleton className={classes.title} />
        <Skeleton className={classes.info} width="50%" />
      </CardContent>
    </Card>
  );
}

GroupStripeItemSkeleton.displayName = 'GroupGridItemSkeleton';
