import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { selectDialog, selectGroup } from 'store/selectors';

import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { GroupMembershipButton } from '../GroupMembershipButton';
import { CANCEL_JOIN_REQUEST_DIALOG } from './dataHooks';

export function CancelJoinRequestDialog() {
  const { t } = useTranslation();
  const { application$, group$ } = useController();

  const { isMobile } = useEnvironment();

  const dialog = useSelector(selectDialog('cancelJoinRequest'));
  const groupId = dialog.params?.groupId as string;
  const group = useSelector(selectGroup(groupId));

  if (!group) {
    return null;
  }

  return (
    <AlertDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      paperProps={{ 'data-hook': CANCEL_JOIN_REQUEST_DIALOG }}
    >
      <DialogTitle
        alert
        title={t('groups-web.viewer.cancel-request-modal.title')}
      />
      <DialogContent alert>
        <DialogContentText alert>
          {t('groups-web.viewer.cancel-request-modal.body', {
            group: group.name,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={handleClose}
          fullWidth={isMobile}
        >
          {t('groups-web.cancel')}
        </Button>
        <GroupMembershipButton
          variant="basic"
          onClick={handleSubmit}
          groupId={groupId}
          outlined={false}
          fullWidth={isMobile}
        >
          {t('groups-web.viewer.cancel-request-modal.cta')}
        </GroupMembershipButton>
      </DialogActions>
    </AlertDialog>
  );

  function handleClose() {
    application$.closeDialog('cancelJoinRequest');
  }

  function handleSubmit() {
    group$.cancelRequest(groupId);
  }
}

CancelJoinRequestDialog.displayName = 'CancelJoinRequestDialog';
