import React from 'react';
import cls from 'classnames';

import { Box } from '../Box';
import { Media } from '../Media';

import classes from './CardMedia.scss';

interface ICardMediaProps extends React.ComponentProps<typeof Media> {}

export function CardMedia(props: ICardMediaProps) {
  return <Media {...props} />;
}

CardMedia.displayName = 'wui/CardMedia';

export function CardMediaPlaceholder(props: React.ComponentProps<typeof Box>) {
  const { className, ...rest } = props;
  return (
    <Box
      width="100%"
      height="100%"
      className={cls(classes.placeholder, className)}
      {...rest}
    />
  );
}

CardMediaPlaceholder.displayName = 'wui/CardMediaPlaceholder';
