import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';
import { ImageRatio } from 'settings/consts';

import type { IGroup } from 'store/groups/types';

import { Box } from 'wui/Box';
import { Skeleton } from 'wui/Skeleton';
import { ListItemIcon } from 'wui/ListItemIcon';
import { AspectRatio } from 'wui/AspectRatio';

import { GroupImage } from 'common/components/GroupImage';

interface IGroupListItemImageProps {
  group: IGroup;
}

export function GroupListItemImage(props: IGroupListItemImageProps) {
  const { group } = props;
  const { isMobile } = useEnvironment();

  const settings = useSettings();

  const aspectRatio = settings.get(settingsParams.imageRatio) as ImageRatio;
  const isSquare = aspectRatio === ImageRatio.square;

  const width = isMobile ? 40 : isSquare ? 72 : 105;

  return (
    <ListItemIcon>
      <Box width={width}>
        <GroupImage
          width={width}
          resize="cover"
          aspectRatio={aspectRatio}
          image={group.coverImage?.image}
        />
      </Box>
    </ListItemIcon>
  );
}

GroupListItemImage.displayName = 'GroupListItemImage';

export function GroupListItemImageSkeleton() {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const aspectRatio = settings.get(settingsParams.imageRatio) as ImageRatio;
  const isSquare = aspectRatio === ImageRatio.square;

  const width = isMobile ? 40 : isSquare ? 72 : 105;

  return (
    <ListItemIcon>
      <AspectRatio width={width} aspectRatio={aspectRatio}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </AspectRatio>
    </ListItemIcon>
  );
}
GroupListItemImageSkeleton.displayName = 'GroupListItemImageSkeleton';
