import React from 'react';

import {
  CancelJoinRequestDialog,
  EventsRestrictionDialog,
  FuturePlanDialog,
  GroupQuestionsDialog,
  JoinGroupDialog,
  LeaveGroupDialog,
  PricingPlansRestrictionDialog,
  PrivateProfileDisclaimer,
} from './dialogs';

export const GroupMembershipDialogs = React.memo(() => {
  return (
    <>
      <GroupQuestionsDialog />
      <JoinGroupDialog />
      <PrivateProfileDisclaimer />
      <CancelJoinRequestDialog />
      <LeaveGroupDialog />
      <PricingPlansRestrictionDialog />
      <FuturePlanDialog />
      <EventsRestrictionDialog />
    </>
  );
});

GroupMembershipDialogs.displayName = 'GroupMembershipDialogs';
