import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';
import { TextAlignment } from 'settings/consts';
import { Alignment } from 'wui/Box/types';

export const textAlignmentsToBoxAlignment: {
  [key in TextAlignment]: Alignment;
} = {
  [TextAlignment.Left]: 'left',
  [TextAlignment.Center]: 'center',
  [TextAlignment.Right]: 'right',
};

export const getGroupsListWidgetCssVars: CustomCssVarsFn = ({
  styleParams,
}) => {
  return {
    showActivity: getDisplayForCssVar(styleParams, 'showActivity', 'block'),
    'showActivity--flex': getDisplayForCssVar(
      styleParams,
      'showActivity',
      'flex',
    ),
    showGroupType: getDisplayForCssVar(styleParams, 'showGroupType', 'block'),
    'showGroupType--inline': getDisplayForCssVar(
      styleParams,
      'showGroupType',
      'inline',
    ),
    showButton: getDisplayForCssVar(styleParams, 'showButton', 'block'),
    'showButton--flex': getDisplayForCssVar(styleParams, 'showButton', 'flex'),
    'showButton--inline': getDisplayForCssVar(
      styleParams,
      'showButton',
      'inline',
    ),
  };
};
