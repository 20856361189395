import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import settingsParams from 'Groups/settingsParams';
import { ImageRatio } from 'settings/consts';

import { AspectRatio } from 'wui/AspectRatio';
import { Card } from 'wui/Card';
import { CardActions } from 'wui/CardActions';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Wire } from 'wui/Wire';
import { Button } from 'wui/Button';

import classes from './GroupGridItem.scss';

export function GroupGridItemSkeleton() {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      className={classes.root}
    >
      <Wire
        cssVarName="showImage--flex"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <AspectRatio
          aspectRatio={settings.get(settingsParams.imageRatio) as ImageRatio}
        >
          <Skeleton variant="rect" width="100%" height="100%" />
        </AspectRatio>
      </Wire>

      <CardContent disableSideGutters className={classes.content}>
        <Skeleton
          className={cls(classes.title, { [classes.mobile]: isMobile })}
          variant="text"
        />
        <Skeleton
          className={cls(classes.info, { [classes.mobile]: isMobile })}
          variant="text"
          width="50%"
        />
      </CardContent>

      <Wire
        cssVarName="showButton--flex"
        legacyFallback={settings.get(groupsListWidgetSettingsParams.showButton)}
      >
        <CardActions
          paddingTop="SP1"
          disableSideGutters
          className={cls(classes.actions, classes.hidden)}
        >
          <Button>&nbsp;</Button>
        </CardActions>
      </Wire>
    </Card>
  );
}

GroupGridItemSkeleton.displayName = 'GroupGridItemSkeleton';
