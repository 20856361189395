import { Direction } from 'wui/Box/types';
import { ImageLayout } from 'settings/consts';

export function getDirection(
  imageLayout: ImageLayout,
  even?: boolean,
): Direction {
  let direction: Direction =
    imageLayout === ImageLayout.LTR ? 'horizontal' : 'horizontal-reverse';
  if (imageLayout === ImageLayout.MIXED && even) {
    direction = 'horizontal';
  }
  return direction;
}
