import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

export function getGroupPrivacyLabel(status?: PrivacyStatus) {
  switch (status) {
    case PrivacyStatus.PUBLIC:
      return 'groups-web.types.public.name';
    case PrivacyStatus.PRIVATE:
      return 'groups-web.types.private.name';
    case PrivacyStatus.SECRET:
      return 'groups-web.types.secret.name';
  }

  return '';
}
