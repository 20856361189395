import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';

export const getFeedCssVars: CustomCssVarsFn = ({ styleParams }) => ({
  showCreatePost: getDisplayForCssVar(styleParams, 'showCreatePost', 'block'),
  'showCreatePost--flex': getDisplayForCssVar(
    styleParams,
    'showCreatePost',
    'flex',
  ),
  showReactions: getDisplayForCssVar(styleParams, 'showReactions', 'block'),
  showShareButton: getDisplayForCssVar(styleParams, 'showShareButton', 'block'),
  'showShareButton--inline-block': getDisplayForCssVar(
    styleParams,
    'showShareButton',
    'inline-block',
  ),
  showFeedTitle: getDisplayForCssVar(styleParams, 'showFeedTitle', 'block'),
  'showFeedTitle--flex': getDisplayForCssVar(
    styleParams,
    'showFeedTitle',
    'flex',
  ),
});
